import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

function Copyright() {
  return (
    <Box
      component='p'
      sx={{
        color: 'text.secondary',
        textAlign: 'center',
        fontSize: '14px',
        mt: 6,
        mb: 2,
      }}
    >
      <Typography variant='body2' color='text.secondary' align='center'>
        <Link color='inherit' href='https://mui.com/'>
          ElectricalThermographyMiami
        </Link>
        {'  '}
      </Typography>

      <Typography variant='body2' color='text.secondary' align='center'>
        {new Date().getFullYear()}
        {'© '}
      </Typography>
    </Box>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

export default function AppFooter() {
  return (
    <Typography component='footer' sx={{ display: 'flex', flexDirection: 'column', bgcolor: 'secondary.light' }}>
      <Container sx={{ my: 8, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box component='a' href='/' sx={iconStyle}>
                <img src='/static/themes/onepirate/appFooterFacebook.png' alt='Facebook' />
              </Box>
              <Box component='a' href='/' sx={iconStyle}>
                <img src='/static/themes/onepirate/appFooterTwitter.png' alt='Twitter' />
              </Box>
            </Grid>
            <Typography variant='h6'>
              <Copyright />
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
