import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box component='section' sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}>
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
        <Box
          component='img'
          src='/static/themes/onepirate/productCurvyLines.png'
          alt='curvy lines'
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component='img' src='/static/icons/buildings.png' alt='suitcase' sx={{ height: 55 }} />
              <Typography variant='h6' sx={{ my: 5 }}>
                Safety and reassurance
              </Typography>
              <Typography variant='h5'>
                With a thermal inspection now required every 10 years, ensure that your home or business is safe and up
                to code for your family or employees.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component='img' src='/static/icons/license.png' alt='graph' sx={{ height: 55 }} />
              <Typography variant='h6' sx={{ textAlign: 'center', mt: 5, mb: 2 }}>
                Quick Inspection and Report
              </Typography>
              <Typography variant='h5'>
                Our inspections are quick and easy. We will have your report to you within 24 hours of the inspection.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box component='img' src='/static/icons/certificate.png' alt='clock' sx={{ height: 55 }} />
              <Typography variant='h6' sx={{ textAlign: 'center', my: 5 }}>
                Licensed and Insured
              </Typography>
              <Typography variant='h5'>
                We are certified, licensed and insured to perform thermal inspections in the state of Florida.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
