import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage =
  'https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      <img style={{ display: 'none' }} src={backgroundImage} alt='increase priority' />
      <Typography color='inherit' align='center' variant='h2' marked='center'>
        Electrical Thermography Miami
      </Typography>
      <Typography color='inherit' align='center' variant='h5' sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}>
        Licensed Electrical Thermographer in Miami, Florida
        <br />
      </Typography>
      <Button
        color='secondary'
        variant='contained'
        size='large'
        component='a'
        onClick={() => {
          // Scroll to get a quote section
          const anchor = document.querySelector('#get-a-quote');

          if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }}
        sx={{ minWidth: 200 }}
      >
        Get a quote
      </Button>
      <Typography variant='body2' color='inherit' sx={{ mt: 2 }}>
        Renew your building certification with a thermographic inspection
      </Typography>
    </ProductHeroLayout>
  );
}
