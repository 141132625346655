/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '@mui/material/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import emailjs from '@emailjs/browser';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .min(2, 'Name should be of minimum 2 characters length')
    .required('Name is required'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  phone: yup
    .string('Enter your phone number')
    .min(10, 'Phone number should be of minimum 10 characters length')
    .required('Phone number is required'),
  company: yup
    .string('Enter your company name')
    .min(2, 'Company name should be of minimum 2 characters length')
    .required('Company name is required'),
  message: yup
    .string('Enter your message')
    .min(10, 'Message should be of minimum 10 characters length')
    .required('Message is required'),
});

function ProductCTA() {

  const form = useRef(); 
  const [open, setOpen] = React.useState(false);

  const initialValues = {
    name: '',
    email: '',
    phone: '',
    company: '',
    message: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setOpen(true);
      formik.resetForm();
      emailjs.sendForm('service_up3ujh9', 'template_877ptqf', form.current, 'VECNaG5fMuoA15Khk').then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component='section' sx={{ mt: 10, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'warning.main',
              py: 8,
              px: 3,
            }}
          >
            <Box
              ref={form}
              component='form'
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
              sx={{ maxWidth: 400 }}
            >
              <Typography variant='h2' component='h2' gutterBottom>
                Request a quote
              </Typography>
              <Typography variant='h5'>
                A memeber of our team will reach out to you shortly to review your specific needs.
              </Typography>
              <Grid container spacing={2} sx={{ mt: 3, mb: 2 }}>
                <Grid item xs={6} sm={6}>
                  <TextField
                    id='name'
                    name='name'
                    label='Name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    id='email'
                    name='email'
                    label='Email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='phone'
                    name='phone'
                    label='Phone'
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='company'
                    name='company'
                    label='Company'
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    error={formik.touched.company && Boolean(formik.errors.company)}
                    helperText={formik.touched.company && formik.errors.company}
                    fullWidth
                    sx={{ mt: 2, mb: 1 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='message'
                    name='message'
                    label='Message'
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{ mt: 2, mb: 1 }}
                  />
                </Grid>
              </Grid>
              <Button type='submit' color='primary' variant='contained' sx={{ width: '100%' }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
              background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
            }}
          />
          <Box
            component='img'
            src='https://images.unsplash.com/photo-1481253127861-534498168948?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1073&q=80'
            alt='call to action'
            sx={{
              position: 'absolute',
              top: 116,
              left: -28,
              right: 0,
              bottom: 0,
              width: '100%',
              maxWidth: 600,
            }}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message='Your request has been submitted. We look forward to speaking with you.'
      />
    </Container>
  );
}

export default ProductCTA;
