import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box component='section' sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}>
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component='img'
          src='/static/themes/onepirate/productCurvyLines.png'
          alt='curvy lines'
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant='h4' marked='center' component='h2' sx={{ mb: 14 }}>
          How it works
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1.</Box>
                <Box component='img' src='/static/icons/calendar.png' alt='calendar' sx={image} />
                <Typography variant='h5' align='center'>
                  Call to receive a quote and schedule an appointment
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2.</Box>
                <Box component='img' src='/static/icons/thermal.png' alt='thermal' sx={image} />
                <Typography variant='h5' align='center'>
                  Our licensed and insured inspector will perform a thermal inspection of your home or business
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>3.</Box>
                <Box component='img' src='/static/icons/report.png' alt='report' sx={image} />
                <Typography variant='h5' align='center'>
                  Receive your report and certification within 24 hours of the inspection
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color='secondary'
          size='large'
          variant='contained'
          component='a'
          onClick={() => {
            let anchor = document.querySelector('#get-a-quote');

            if (anchor) {
              anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }}
          sx={{ mt: 8 }}
        >
          Get started
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
